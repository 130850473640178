angular.module('fingerink')
    .service('stepInsertSignatoriesModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                keyboard: false,
                templateUrl: 'modals/stepInsertSignatoriesModal.tpl.html',
                controller: 'stepInsertSignatoriesModalCtrl'

            });


            return this.modalInstance;
        };

    }).controller('stepInsertSignatoriesModalCtrl', function stepInsertSignatoriesModalCtrl($rootScope, $scope, $uibModalInstance) {
        $scope.ok = function (s) {
            $uibModalInstance.close(s);
        };
    });
